html {
  margin: 0;
  line-height: 1.5;
  font-size: 16px;
  tab-size: 4;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

